<template>
  <Layout>
    <div class="mb-3">
      <div class="ride-steps mb-4">
        <router-link to="/ride/step-1" class="ride-step active">
          <div class="ride-step-index">
            <span class="material-symbols-rounded"> done </span>
          </div>
          <div class="ride-step-title">Choose Route</div>
        </router-link>
        <router-link to="/ride/step-2" class="ride-step active">
          <div class="ride-step-index">
            <span class="material-symbols-rounded"> done </span>
          </div>
          <div class="ride-step-title">Select Company</div>
        </router-link>
        <div class="ride-step active">
          <div class="ride-step-index">3</div>
          <div class="ride-step-title">Payment</div>
        </div>
      </div>

      <b-alert
        :variant="msg.type"
        dismissible
        class="mt-3"
        v-model="msg.has"
        :show="msg.text"
        >{{ msg.text }}</b-alert
      >

      <div class="row">
        <div class="col-md-6 col-xl-4 mb-3">
          <h3 class="header-title">Route Information</h3>
          <div class="card">
            <div class="card-body order-route-text" v-if="order">
              <p v-if="order.pfrom_addr">
                <b>Starting Point</b>
                {{ order.pfrom_addr }}
              </p>

              <p v-if="order.pto_addr">
                <b>End Point</b>
                {{ order.pto_addr }}
              </p>

              <p v-if="order.distance">
                <b>Distance</b>
                {{ order.distance }} miles
              </p>

              <p v-if="order.car && order.car.cartype">
                <b>Car type</b>
                {{ carTypesLabel(order.car.cartype) }}
              </p>

              <p v-if="order.vendor && order.vendor.company_name">
                <b>Vendor name</b>
                {{ order.vendor.company_name }}
              </p>

              <p v-if="order.price">
                <b>Price</b>
                <span class="price">$ {{ order.price }}</span>
              </p>

              <!-- <pre>order: {{ order }}</pre> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-4 mb-3">
          <h3 class="header-title">Choose card</h3>
          <div class="card">
            <div class="card-body">
              <div v-if="loadedStripe">
                <stripe-element-payment
                  ref="paymentRef"
                  :pk="pk"
                  :elements-options="elementsOptions"
                  :stripe-account="stripeAccount"
                  :confirm-params="confirmParams"
                  :testMode="false"
                  locale="en"
                  @error="handleErrorStripe"
                  @element-ready="wait = false"
                />
              </div>
            </div>
          </div>
          <div v-if="order && order.price" class="d-flex">
            <button class="btn btn-primary" @click="payOrder" :disabled="wait">
              Confirm payment $ {{ order.price }}
            </button>

            <!-- cancel order -->
            <button
              class="btn btn-ico btn-danger ml-auto"
              @click.prevent="confirmCancel(order)"
              :disabled="waitCancel"
              title="Cancel order"
            >
              <span class="material-symbols-rounded"> close </span>
            </button>
          </div>
        </div>
      </div>

      <!-- <pre>client: {{client}}</pre> -->
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import store from "@/store";
import router from "@/router";
import axios from "axios";
import urls from "@/urls";
import { mapState, mapGetters, mapActions } from "vuex";
import { carTypes } from "@/components/data";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | Book a ride, step 3: Payment",
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log("step-3, beforeRouteEnter... ");

  //   if (store.getters.authorized && store.getters.user.role === "c") {
  //     // console.log("id: ", store.getters.client?.currentorders?.items[0]?.id);
  //     // console.log("status: ", store.getters.client?.currentorders?.items[0]?.status);
  //     if (
  //       !store.getters.client?.currentorders?.items[0]?.id ||
  //       store.getters.client?.currentorders?.items[0]?.status !== 1
  //     ) {
  //       router.push("/ride/step-2").catch(() => {});
  //     }
  //   }

  //   if (store.getters.authorized && store.getters.user.role === "c") {
  //     const ordersInWork = store.getters.client.currentorders.items.filter(
  //       (order) => order.status === 3 || order.status === 4
  //     );

  //     // if has orders in work redirect to - Current orders (/client/orders)
  //     if (ordersInWork.length) {
  //       router.push("/client/orders").catch(() => {});
  //     } else {
  //       next();
  //     }
  //   }
  // },
  components: {
    Layout,
    StripeElementPayment,
  },
  data() {
    return {
      msg: {
        has: false,
        type: "",
        text: "",
      },

      carTypes: [...carTypes],

      wait: true,
      waitCancel: true,

      loadedStripe: false,
      pk: "",
      elementsOptions: {
        appearance: {}, // appearance options
      },
      stripeAccount: "",
    };
  },
  mounted() {
    this.generatePaymentIntent();
  },
  computed: {
    ...mapGetters(["user", "opt", "client"]),
    order() {
      return this.client.orderlist?.items?.find(order => order.id == this.$route.query?.id) || null
    },
    confirmParams() {
      return {
        return_url: `${window.location.origin}/payment-result?id=${this.order.id}`, // success url
      }
    },
  },
  methods: {
    ...mapActions(["fetchUserDataCustomer", "clientCancelOrder"]),
    carTypesLabel(id) {
      return this.carTypes.find((t) => t.id === id).label;
    },
    async generatePaymentIntent() {
      this.loadedStripe = false;
      this.wait = true;
      this.waitCancel = true;

      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      try {
        const paymentIntent = await axios({
          method: "post",
          url: urls.URL_PAYMENT_CREATE,
          data: { order_id: this.order.id },
        });

        // console.log("paymentIntent: ", paymentIntent);
        if (paymentIntent.data.acc) {
          this.stripeAccount = paymentIntent.data.acc;
        } else {
          this.stripeAccount = "";
        }
        // console.log("stripeAccount: ", this.stripeAccount);

        this.pk = paymentIntent.data.pk;
        this.elementsOptions.clientSecret = paymentIntent.data.clientSecret;

        this.loadedStripe = true;
      } catch (error) {
        console.log("paymentIntent, Error: ", error);

        this.msg = {
          has: true,
          type: "danger",
          text: "paymentIntent error...",
        };

        if (error.response?.status) {
          if (error.response.statusText) {
            this.msg.text = `Error: ${error.response.status} ${error.response.statusText}`;
          } else if (error.response.data?.detail) {
            this.msg.text = `Error: ${error.response.status} ${error.response.data.detail}`;
          } else if (error.response.data?.error) {
            this.msg.text = `Error: ${error.response.status} ${error.response.data.error}`;
          }
        }
      } finally {
        this.waitCancel = false;
      }
    },
    payOrder() {
      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      this.$refs.paymentRef.submit();
    },
    handleErrorStripe(err) {
      // console.log("handleErrorStripe, err: ", err.message);
      this.msg = {
        has: true,
        type: "danger",
        text: err.message,
      };
    },
    htmlToVNode(html) {
      return [this.$createElement("div", { domProps: { innerHTML: html } })];
    },
    confirmCancel(order) {
      console.log("confirmCancel, order: ", order);
      const msg = this.htmlToVNode(
        `Do you want to cancel order: #${order.id} ?`
      );

      this.$bvModal
        .msgBoxConfirm(msg, {
          title: "Cancel order confirmation",
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((sure) => {
          if (sure) {
            this.cancelOrder(order.id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async cancelOrder(id) {
      // console.log("cancelOrder, id: ", id);
      this.wait = true;
      this.waitCancel = true;

      try {
        await this.clientCancelOrder(id);
        // await this.fetchUserDataCustomer();
        this.$router.push("/ride/step-2");
      } catch (error) {
        console.log("cancelOrder, error: ", error);
      } finally {
        this.wait = false;
        this.waitCancel = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.order-route-text p {
  font-size: 16px;
  font-weight: 400;
  color: #505655;
  margin: 0.75rem 0 0 0;
  padding: 0;

  &:first-child {
    margin-top: 0;
  }

  b {
    display: block;
    font-size: 14px;
    font-weight: 600;
  }

  .price {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #199f97;
  }
}
</style>
